footer {
  background-color: #004669;
  padding: 58px 0 101px;

  @media (max-width: 1000px) {
    background-color: #fff;
    padding: 0;
  }
  .footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .logoWrap {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
    }

    ul {
      // flex: 1;
      list-style: none;
      padding: 0;
      margin: 0;
      color: #ffffff;

      li,
      a {
        color: #ffffff;
        text-decoration: none;
        font-size: 24px;
        line-height: 26px;
      }

      &.nav {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        a {
          transition: 0.2s;
          display: inline-block;
          text-transform: capitalize;
          &:hover {
            transform: scale(1.1);
          }
        }
      }

      &.contact {
        // max-width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        li {
          &.head {
            padding-left: 26px;
            font-weight: 600;
            margin-top: 10px;

            &:first-child {
              margin-top: 0;
            }
          }
          &.icon {
            display: flex;
            line-height: 1;
            margin-top: 5px;

            &:last-child {
              margin-bottom: 0;
            }
            .icon_wrap {
              margin-right: 14px;
              width: 17px;

              display: flex;
              justify-content: center;
              align-items: flex-start;

              svg {
                margin-top: 5px;
                // width: ;
                height: auto;
              }
            }
          }
        }
      }
    }
  }

  .footer_mobile {
    .head_logo {
      background-color: #004669;
      padding: 27px 0px 26px;
    }

    .content {
      position: relative;
      padding-top: 33px;
      padding-bottom: 26px;

      .decor {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    ul {
      flex: 1;
      list-style: none;
      padding: 0;
      margin: 0;
      color: #004669;

      li,
      a {
        color: #004669;
        text-decoration: none;
        font-size: 24px;
        line-height: 26px;
      }

      &.contact {
        li {
          &.icon {
            display: flex;
            line-height: 1;
            margin-top: 5px;
            font-size: 16px;
            line-height: 18px;

            &:last-child {
              margin-bottom: 0;
            }
            a {
              font-size: 16px;
              line-height: 18px;
            }
            .icon_wrap {
              margin-right: 14px;
              width: 17px;

              display: flex;
              justify-content: center;
              align-items: flex-start;

              svg {
                margin-top: 5px;
                // width: ;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
