.nav {
  @media (max-width: 768px) {
    // display: ;
  }
  ul {
    display: flex;

    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-right: 36px;
      position: relative;

      &::after {
        position: absolute;
        right: -18px;
        top: 0;
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: #fff;
      }

      &:last-child {
        margin-right: 0;

        &::after {
          display: none;
        }
      }

      a {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }

  &Color {
    ul {
      li {
        &::after {
          position: absolute;
          right: -18px;
          top: 0;
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background-color: #000;
        }
        a {
          color: #000000;
        }
      }
    }
  }
}
