.wrap {
  background-color: #004669;
  padding: 30px 0 100px 0;

  h2 {
    color: #fff !important;
    margin: 0;
    margin-bottom: 5px;
  }

  .stages_icons_wrap {
    display: flex;
    padding: 100px 0;

    @media (max-height: 1000px) {
      padding: 25px 0;
    }
  }
}
