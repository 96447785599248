@font-face {
  font-family: "Myriad Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Regular"),
    url("./assets/fonts/MYRIADPRO-REGULAR.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed"),
    url("./assets/fonts/MYRIADPRO-COND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed Italic"),
    url("./assets/fonts/MYRIADPRO-CONDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Light";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Light"),
    url("./assets/fonts/MyriadPro-Light.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Semibold"),
    url("./assets/fonts/MYRIADPRO-SEMIBOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Semibold Italic"),
    url("./assets/fonts/MYRIADPRO-SEMIBOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Condensed"),
    url("./assets/fonts/MYRIADPRO-BOLDCOND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold"),
    url("./assets/fonts/MYRIADPRO-BOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Italic"),
    url("./assets/fonts/MYRIADPRO-BOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Condensed Italic"),
    url("./assets/fonts/MYRIADPRO-BOLDCONDIT.woff") format("woff");
}

body {
  margin: 0;
  font-family: "Myriad Pro Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

h1,
p,
ul,
a {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
