.main_title {
  padding: 40px 40px;
  border-radius: 25px;
  color: #ffffff;

  background-color: rgba($color: #000000, $alpha: 0.45);

  display: flex;
  flex-direction: column;

  width: 50%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: auto;
    padding: 13px;
    border-radius: 5px;
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 19px;
    }
  }
  .line {
    background-color: rgba(204, 34, 41, 1);
    width: 100%;
    height: 2px;
    margin: 15px 0;

    @media (max-width: 768px) {
      margin: 8px 0;
    }
  }
  p {
    font-size: 20px;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
}

.astafiev {
  color: #000000;
  background: none;

  .line {
    background-color: #000000;
  }
}
