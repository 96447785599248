.logo_wrap {
  flex: 1;
  @media (max-width: 768px) {
    max-width: 50%;
    svg {
      width: 100%;
      height: auto;
    }
  }
}
