.slick-dots {
  li {
    width: 6px;

    button {
      width: 6px;
      height: 6px;
    }
  }
  button::before {
    width: 10px !important;
    height: 10px !important;
    opacity: 1 !important;
    background-color: rgba(107, 133, 158, 1);
    border-radius: 50%;
    color: rgba($color: #ffffff, $alpha: 0) !important;

    @media (max-width: 1000px) {
      width: 6px !important;
      height: 6px !important;
    }
  }
  .slick-active {
    button::before {
      background-color: rgba(204, 34, 41, 1);
    }
  }
}

.slick-prev {
  left: -50px;

  @media (max-width: 1000px) {
    left: -25px;
  }
}

#resultSlider .slick-prev {
  @media (max-width: 1000px) {
    top: 33px;
  }
}

#news .slick-prev {
  @media (max-width: 1000px) {
    top: 80px;
  }
}
.slick-next {
  right: -50px;

  @media (max-width: 1000px) {
    right: -25px;
  }
}
#resultSlider .slick-next {
  @media (max-width: 1000px) {
    top: 33px;
  }
}
#news .slick-next {
  @media (max-width: 1000px) {
    top: 80px;
    right: -30px;
  }
}

#photos .slick-next {
  display: none !important;
}
