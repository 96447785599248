.time_map {
  display: flex;
  flex-direction: column;
  position: relative;

  .lineBetween {
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    height: 100%;
    width: 2px;
    background-color: #fff;
  }

  .end_date_wrap {
    width: 50%;
    display: flex;
    align-items: flex-end;
  }
  .start_date_wrap {
    width: 50%;
    display: flex;
    align-items: flex-start;
  }

  .row {
    display: flex;
    .dashed_line {
      width: 100%;
      height: 2px;
      background: url("../../../assets/img/decors/dashed-line.svg") center;
      background-size: cover;

      position: relative;

      .white_marker {
        position: absolute;
        width: 11px;
        height: 11px;
        background-color: #fff;
        border-radius: 50%;
        top: -5.5px;
        // left: -5.5px;
      }
    }
    .start_date,
    .end_date {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 6px 17px;
      margin-bottom: -20px;

      p {
        font-size: 30px;
        color: #004669;
        font-weight: 600;
      }
    }

    .start_date {
      margin-top: -20px;
    }
    .empty {
      min-width: 50% !important;
    }
  }
}

.item {
  color: #ffffff;
  width: 50%;
  .line {
    width: 100%;
    height: 2px;
    background-color: #fff;
    margin: 15px 0 20px 0;

    position: relative;

    .red_marker {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      background-color: #cc2229;
      position: absolute;
      top: -11px;
      border-radius: 50%;
    }
  }
  p {
    font-size: 30px;
    font-weight: 600;
  }
  span {
    display: inline-block;
    font-size: 24px;

    @media (max-width: 1000px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.time_map_mobile {
  display: flex;
  flex-direction: column;
  position: relative;

  .lineBetween {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: #fff;
  }

  .end_date_wrap {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse !important;
    // align-items: flex-end;
  }
  .start_date_wrap {
    width: 100%;
    display: flex;
    flex-direction: row-reverse !important;

    // align-items: flex-start;
  }

  .row {
    display: flex;
    .dashed_line {
      width: 100%;
      height: 2px;
      background: url("../../../assets/img/decors/dashed-line.svg") center;
      background-size: cover;

      position: relative;

      .white_marker {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        top: -4px;
        right: -4.5px;
        // left: -5.5px;
      }
    }
    .start_date,
    .end_date {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 3px 13px;

      p {
        font-size: 16px;
        line-height: 18px;
        height: min-content;
        box-sizing: border-box;
        color: #004669;
        font-weight: 600;
      }
    }

    .start_date {
      margin-top: -10px;
    }
    .end_date {
      margin-bottom: -10px;
    }
  }

  .item {
    color: #ffffff;
    width: 100%;
    margin-bottom: 30px;

    &:first-child {
      margin-top: 40px;
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #fff;
      margin: 5px 0;

      position: relative;

      .red_marker {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background-color: #cc2229;
        position: absolute;
        top: -5px;
        right: -4.5px !important;
        border-radius: 50%;
      }
    }
    p {
      font-size: 16px;
      line-height: 18px;
      padding-right: 30px !important;
    }
    span {
      display: inline-block;
      font-size: 16px;
      line-height: 18px;
      padding-right: 30px !important;
    }
  }
}
