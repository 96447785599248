.container {
  width: 1280px;
  margin: 0 auto;

  height: 100%;
  // flex: 1;

  @media (max-width: 1350px) {
    width: 80%;
  }

  @media (max-width: 1000px) {
    width: 80%;
  }
}
