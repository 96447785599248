.wrap {
  height: 100vh;
  width: 100vw;
  background: #004669;

  position: relative;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .logo {
      margin-bottom: 100px;
      width: 100%;
      svg {
        width: 100%;
      }
    }
  }

  p,
  a {
    font-size: 40px;
    text-align: center;
    color: #ffffff;

    @media (max-width: 1000px) {
      font-size: 20px;
    }
  }

  p {
    margin-bottom: 20px;
  }

  svg.decor {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
