.wrap {
  h2 {
    color: #ffffff !important;
    margin: 0;
  }
  .slider {
    padding: 70px 0 80px 0;

    @media (max-width: 1000px) {
      padding: 32px 0 48px 0;
    }
  }
}
