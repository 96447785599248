.item {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1000px) {
    flex-direction: row;
    gap: 13%;
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;

    @media (max-width: 1000px) {
      width: 38%;
      height: auto;
    }
  }

  .emptyImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    border: 2px solid #004669;

    box-sizing: border-box;

    @media (max-width: 1000px) {
      width: 38%;
      height: auto;
    }
    img {
      width: 60%;
      // height: auto;
    }
  }

  div {
    @media (max-width: 1000px) {
      width: 49%;
    }
  }

  p {
    margin: 34px 0 27px 0;

    font-size: 24px;
    font-weight: 600;
    color: #004669;

    @media (max-width: 1000px) {
      font-size: 14px;
      line-height: 16px;
      margin: 0;
      margin-bottom: 6px;
    }
  }

  span {
    font-size: 24px;
    font-weight: 300;
    color: #004669;

    @media (max-width: 1000px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
