.wrapper {
  height: 100vh;
  padding: 50px 0;

  box-sizing: border-box;
  background-position: center;
  background-color: #000;
  background-size: 100% 100%;

  @media (max-width: 1000px) {
    padding: 30px 0;
  }

  @media (max-width: 1000px) {
    background-position: 1500px -280px;
    background-size: auto 135%;
  }

  @media (max-width: 768px) {
    background-position: 1500px -280px;
    background-size: auto 135%;
  }

  @media (max-width: 500px) {
    background-position: 1350px -280px;
    background-size: auto 135%;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .documents_list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      list-style: none;
      li {
        padding: 2px 7px 2px 44px;
        background-color: rgba($color: #ffffff, $alpha: 0.6);
        border-radius: 12px;

        font-size: 23px;
        text-transform: lowercase;

        font-weight: lighter;

        margin-bottom: 10px;

        @media (max-width: 1000px) {
          font-size: 16px;
          line-height: 18px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: #000000;
          text-decoration: none;
          position: relative;

          transition: 0.3s;

          &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            border: 2px solid rgba(204, 34, 41, 1);
            border-radius: 50%;
            box-sizing: border-box;

            position: absolute;
            left: -30px;
            top: calc(50% - 8px);
          }

          &:hover::before {
            background-color: rgba(204, 34, 41, 1);
          }
        }
      }
    }
  }
}
