.wrap {
  h2 {
    margin-top: 50px;
  }

  .juri_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 100px;
    row-gap: 70px;

    @media (max-width: 1350px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 25px;
      row-gap: 25px;
    }

    @media (max-width: 1000px) {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0px;
      row-gap: 25px;
    }
  }
  margin-bottom: 100px;
  @media (max-width: 1000px) {
    margin-bottom: 25px;
  }
}
