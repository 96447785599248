.modal_container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;

  z-index: 2;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;

  .close {
    position: absolute;
    top: 70px;
    right: 70px;

    cursor: pointer;
  }

  .prev,
  .next {
    cursor: pointer;
    padding: 20px;
    transition: 0.3s;
    &:hover {
      transform: scale(1.3);
    }
  }
  .image {
    width: 50%;
    margin: 0 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    p {
      position: absolute;
      font-size: 40px;
      font-weight: 600;
    }

    img {
      width: 100%;
    }
  }
}
